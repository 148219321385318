export const getTabIndex = (
  activeTab: string | undefined,
  isSelected: boolean,
  tabListIndex: number
): 0 | -1 => {
  const isFirstTab = tabListIndex === 0;
  if (activeTab) {
    // If there is an active tab, and
    // if the current tab is selected, it should be focusable via keyboard.
    // Other tabs will be focusable via the keydown handler on the list.
    return isSelected ? 0 : -1;
  } else {
    // If there is no active tab, and
    // if the current tab is the first tab in the list, it should be focusable via keyboard.
    // Other tabs will be focusable via the keydown handler on the list.
    return isFirstTab ? 0 : -1;
  }
};
