import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';

import {
  fetchProvidersV9,
  fetchProviderSlotCounts,
  toggleMobileFacets,
  getUpdatedSearch as baseGetUpdatedSearch,
  searchSelector,
  getProviderIds,
  querySelector
} from '../behaviors/search-v9';
import {
  setPatientRel,
  setPurpose,
  resetAvailability,
  fetchGlobalPurposesByPatientRelV9,
  fetchPurposesByProviderId,
  fetchSlots
} from '../behaviors/availability';
import { isError } from '../behaviors/error';
import { isLoading } from '../behaviors/loading';
import { isSlotsCountLoading } from '../behaviors/slotsCountLoading';
import { getProvidersByIds } from '../behaviors/providers';
import { configSelector } from '../behaviors/configuration';
import { tokensSelector } from '../behaviors/tokens';
import { pages } from '../tracking/constants';
import { decorateSearchTracking } from './logging-decorators';
import { pageViewEvent } from '../tracking/tracking-utils';
import { useCcfDemographicsLocation } from '../hooks';
import { useMigrateV8SearchParams } from './hooks/migrate-v8-search-params';

import SearchV9 from '../search-v9';
import { productNameSelector } from '../behaviors/product-name';

const mapStateToProps = (state, ownProps) => {
  const config = configSelector(state);

  const {
    direct_book: {
      patient_type: { buttons: availabilityButtonsConfig } = {}
    } = {}
  } = config;

  const log = (message, event_data = {}) => {
    const additionalTracking = decorateSearchTracking({ state });

    ownProps.log(message, { ...event_data, ...additionalTracking });
  };

  const distanceSort = _get(
    config,
    'location_facet.custom_sort_order',
    'distance'
  );

  const providers = getProvidersByIds(state, getProviderIds(state)); // we could use reselect for this.
  const loading = isLoading(state);
  const slotsCountLoading = isSlotsCountLoading(state);
  const error = isError(state);

  let apptInfo = {
    loading: state.availability.loading,
    purposeOptions: state.availability.purposeOptions
  };
  if (
    state.availability &&
    state.availability.patientRel &&
    state.availability.purpose
  ) {
    apptInfo = {
      ...apptInfo,
      relationship: state.availability.patientRel,
      purpose: state.availability.purpose
    };
  }

  const currentQuery = querySelector(state);
  const currentLocation = ownProps.location;

  const getUpdatedSearch = (modifications = []) => {
    return baseGetUpdatedSearch(
      config,
      currentQuery,
      currentLocation,
      modifications
    );
  };

  return {
    customerCode: state.customerCode,
    productName: productNameSelector(state),
    config,
    log,
    distanceSort,
    providers,
    loading,
    slotsCountLoading,
    getUpdatedSearch,
    error,
    apptInfo,
    availabilityButtonsConfig,
    searchProviderIds: state.searchV9.providerIds,
    slotsByProviderId: state.availability.slotsByProviderId,
    slotsStatusByProviderId: state.availability.slotsStatusByProviderId,
    hasFetchingSlotCountsFailed: state.availability.hasFetchingSlotCountsFailed,
    availablePurposesByProviderId:
      state.availability.availablePurposesByProviderId,
    tokens: tokensSelector(state),
    ...searchSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchProvidersV9,
      fetchProviderSlotCounts,
      toggleMobileFacets,
      setPatientRel,
      setPurpose,
      resetAvailability,
      fetchGlobalPurposesByPatientRelV9,
      fetchPurposesByProviderId,
      fetchSlots
    },
    dispatch
  );
};

function SearchContainerComponentV9(props) {
  const {
    customerCode,
    searchSummary,
    location,
    log,
    fetchProvidersV9,
    fetchProviderSlotCounts,
    loading,
    config,
    tokens,
    getUpdatedSearch,
    history
  } = props;

  const { patientZip, patientZipSearchLocation, patientZipPending } =
    useCcfDemographicsLocation({
      config,
      customerCode,
      bcsToken: tokens?.bcsToken
    });

  const [isUserLocationFetched, setIsUserLocationFetched] =
    useState(patientZipPending);

  const { completed: areV8ParamsChecked } = useMigrateV8SearchParams({
    location,
    history
  });

  const hasCompletedPreprocessing = isUserLocationFetched && areV8ParamsChecked;
  // const isPreprocessing = !isUserLocationFetched || !areV8ParamsChecked;

  useEffect(() => {
    if (patientZipPending) {
      // don't do anything until pre-processing is complete
      return;
    }

    if (patientZipSearchLocation && !searchSummary.location) {
      // if we have an auto-geolocated zip code from demographics on a ccf customer,
      // and we're not already searching for a location, update the search to use that zip
      history.push(
        getUpdatedSearch([
          {
            action: 'append',
            key: 'location',
            value: patientZipSearchLocation
          },
          {
            action: 'append',
            key: 'display_location',
            value: patientZip
          },
          {
            action: 'delete_key',
            key: 'distance'
          }
        ])
      );
    }
    setIsUserLocationFetched(true);
    // remove searchSummary.location from deps array to allow totally clearing filters even in ccf
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientZipPending, patientZipSearchLocation, searchSummary.location]);

  useEffect(() => {
    async function doFetch() {
      // if the current search is already in the state, don't reload.
      // This is true for server side rendering and coming back from profile pages.

      if (!hasCompletedPreprocessing) {
        // don't do anything until pre-processing is complete
        return;
      }

      // temporary fix for specialty pages
      // when there is a difference between query_string and search params the application finds all providers instead of using specialty and location search criteria.
      if (location.pathname.startsWith('/specialty')) {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('page')) {
          if (searchParams.size === 1) {
            searchSummary.query_string = `?${searchParams.toString()}`;
          }
        }
      }

      if (location.search !== searchSummary?.query_string) {
        await fetchProvidersV9(location);
        await fetchProviderSlotCounts(location);
      } else {
        await fetchProviderSlotCounts(location);
      }
    }
    doFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, hasCompletedPreprocessing]);

  useEffect(() => {
    // log when search has changed
    if (!loading) {
      log('user_action.search_results.view_search_results', searchSummary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSummary?.query_string, loading]);

  useEffect(() => {
    // log page view on first load
    log(pageViewEvent(pages.SEARCH_RESULTS));
  }, []);

  return <SearchV9 {...props} />;
}

const SearchContainerV9 = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchContainerComponentV9);

// server side fetch function
SearchContainerV9.prototype.serverSideExecute = async function ({
  req,
  res,
  store
}) {
  // this.selector.props are the props collected from `connect` and `mapStateToProps`
  // We need to access these instead of `this.props`
  const { fetchProvidersV9, config, location } = this.selector.props;

  if (config?.third_party?.cerner_healthelife) {
    // disable search SSR for cerner integrations to allow auto-zip to work, which only works in client.
    return;
  }

  await fetchProvidersV9(location, { req });

  // uncomment to enable server-side slot rendering. Disabled for now to keep parity with what
  // existing flask SSR is doing. Enabling here will increase initial page load time for however long slots take.
  // await fetchProviderSlotCounts(location, { req });

  // render 404 if search returned no results
  const { searchV9 } = store.getState();
  if (searchV9 && searchV9.totalProviders === 0) {
    res.status(404);
  }
};

export default SearchContainerV9;
