import { SearchFacet, Term } from 'Src/types';
import messages from './messages';

const HIDDEN_FACETS = [
  'location',
  'locations.primary_marketable_location_id',
  'locations.associated_marketable_location_ids',
  'provider.id'
];

/**
 * Check if a facet should be shown
 * @param facet
 * @returns {boolean}
 */
export const shouldShowFacet = (facet: SearchFacet) =>
  !HIDDEN_FACETS.includes(facet.field) && !!facet?.terms?.length;

interface AppliedTerm {
  field: string;
  term: Term;
}

/**
 * Get the applied filters from the facets
 * @param facets
 */
const getAppliedTerms = (facets: SearchFacet[]) => {
  if (facets == null) return [];

  return facets
    .filter(shouldShowFacet)
    .reduce<AppliedTerm[]>((acc, { field, terms }) => {
      terms
        .filter((term) => term.applied)
        .forEach((term) => acc.push({ field, term }));
      return acc;
    }, []);
};

/**
 * Get the count of unique applied filters
 * @param facets
 * @returns {number}
 */
export const getUniqueAppliedFiltersCount = (facets: SearchFacet[]) =>
  new Set(getAppliedTerms(facets).map((filter) => filter.field)).size;

export const getAllFiltersLabel = (
  countOfAppliedFilters: number,
  isScreenMediumOrBelow: boolean
) => {
  if (countOfAppliedFilters == 0) {
    if (isScreenMediumOrBelow) {
      return messages.allFiltersChipWithoutCountMobile;
    }
    return messages.allFiltersChipWithoutCount;
  }
  if (isScreenMediumOrBelow) {
    return messages.allFiltersChipWithCountMobile;
  }
  return messages.allFiltersChipWithCount;
};
