import {
  V9_FACET_FIELD_MIGRATIONS,
  V8_TO_V9_FIELD_MIGRATIONS
} from './field-migration-map';

import { splitOnce } from 'Common/utils/splitOnce';

const V8_TO_V9_FIELDS = getV8ToV9Table(V9_FACET_FIELD_MIGRATIONS);

const V9_FACET_VALUE_MIGRATIONS = {
  t: 'true',
  f: 'false',
  T: 'true',
  F: 'false',
  yes: 'true',
  no: 'false',
  YES: 'true',
  NO: 'false',
  True: 'true',
  False: 'false'
};

/**
 * V9_FACET_FIELD_MIGRATIONS is a migration of v9 to v8 fields, but for converting from v8 to v9
 * it's more convenient to have a table of v8=>v9 lookups. This function flips the table from v9=>v8 to
 * v8=>v9 lookups. You might ask "why don't we have a mapping of v8 to v9 in the first place" and the answer
 * to that is so that we can share the same lookup table as the facets v8 to v9 scripts, without needing to
 * transform it between these files. So we have this utility function to make the transition for us.
 * @param {*} fieldMigrations Mapping of v9 fields to fields that exist in v8 that should map to that v9 field
 * @returns Mapping of v8 to v9 fields
 */
function getV8ToV9Table(fieldMigrations) {
  const table = {};
  Object.entries(fieldMigrations).forEach(([v9Field, v8Fields]) => {
    if (Array.isArray(v8Fields)) {
      v8Fields.forEach((v8Field) => {
        table[v8Field] = v9Field;
      });
    } else {
      table[v8Fields] = v9Field;
    }
  });
  return table;
}

/**
 * Given an array of v8 filters, return an array of those filters converted to their v9 equivalents
 * @param {Array<String>} filters
 * @returns {Array<String>} v9 filters
 */
function getConvertedFilters(filters) {
  if (filters == null) return [];

  if (!Array.isArray(filters)) {
    filters = [filters];
  }

  return filters.map((v8Filter) => {
    const [filterName, filterValue] = splitOnce(v8Filter, ':');
    let newFilterName = filterName;
    let newValue = filterValue;
    if (filterName in V8_TO_V9_FIELDS) {
      newFilterName = V8_TO_V9_FIELDS[filterName];
    }
    if (filterValue in V9_FACET_VALUE_MIGRATIONS) {
      newValue = V9_FACET_VALUE_MIGRATIONS[filterValue];
    }
    // replace v8 '|' with v9 '#'
    if (typeof newValue === 'string') {
      newValue = newValue.split('|').join('#');
    }
    return `${newFilterName}:${newValue}`;
  });
}

/**
 * Convert all v8 fields in the supplied object to their v9 equivalents. Any non matching params in the
 * supplied object are retained
 * @param {Object} fields query parameters
 * @returns
 */
function getConvertedFields(fields = {}) {
  const v9Fields = {};
  const v9Filter = new Set();
  Object.entries(fields).forEach(([key, value]) => {
    const { field, remove = '', filter } = V8_TO_V9_FIELD_MIGRATIONS[key] || {};
    if (field) {
      if (filter) {
        v9Filter.add(...filter);
      }

      v9Fields[field] = value?.replace(remove, '');
    } else {
      v9Fields[key] = value;
    }
  });
  return { v9Fields, v9Filter };
}

/**
 * Given an object -- generally from query params -- of v8 specific search filter params,
 * Convert all relevant fields to their v9 equivalents. Any non-search related params in the
 * supplied object are retained
 * @param {Object} searchParams
 * @returns {Object}
 */
export function getConvertedSearchParams(searchParams = {}) {
  const { filter } = searchParams;
  const { v9Fields: convertedFields, v9Filter } =
    getConvertedFields(searchParams);

  if (filter || v9Filter.size > 0) {
    const convertedFilters = getConvertedFilters(filter);
    // dedupe filters
    convertedFields.filter = [...new Set([...convertedFilters, ...v9Filter])];
  }
  return convertedFields;
}
