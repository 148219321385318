export const CHIP_ROLE = 'button';
// This value does not include the Location chip
export const DEFAULT_UNAPPLIED_CHIP_COUNT = 5;

export const TRACKING_EVENTS = {
  ALL_FILTERS_OPEN_DRAWER: 'user_action.filters.all_filters.open_drawer',
  ALL_FILTERS_CLOSE_DRAWER: 'user_action.filters.all_filters.close_drawer',
  SCROLL_LEFT: 'user_action.filters.scroll_left',
  SCROLL_RIGHT: 'user_action.filters.scroll_right',
  MODIFY_FILTER: 'user_action.filters.modify_filter',
  OPEN_FILTER_MENU: 'user_action.filters.open_filter_menu',
  CLOSE_FILTER_MENU: 'user_action.filters.close_filter_menu',
  FILTER_PANEL_VIEW_RESULTS: 'user_action.filters.view_results'
};
