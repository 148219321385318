import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PMCConfig } from '@kyruus/types';
import { FilterChip } from './FilterChip';
import { useFocusManager } from './hooks/useFocusManager';
import {
  GetUpdatedSearch,
  Log,
  SearchFacet,
  SearchModification,
  SearchSummary
} from 'Src/types';
import { getOrderedFacets } from './utils';
import { CHIP_ROLE, TRACKING_EVENTS } from '../constants';
import { HorizontalFilters } from '../styles';

interface BaseFilterChipsProps {
  config: PMCConfig;
  facets: SearchFacet[];
  getUpdatedSearch: GetUpdatedSearch;
  log: Log;
  searchSummary: SearchSummary;
}

type FilterChipsProps = BaseFilterChipsProps & RouteComponentProps;

export const FilterChips = withRouter(
  ({
    config,
    facets,
    getUpdatedSearch,
    history,
    log,
    searchSummary
  }: FilterChipsProps) => {
    const [activeChipIndex, setActiveChipIndex] = useState(0);

    const { 'aria-orientation': ariaOrientation, onKeyDown } = useFocusManager({
      itemElementRole: CHIP_ROLE,
      itemParentRole: 'toolbar',
      orientation: 'horizontal'
    });

    const { appliedCount, orderedFacets } = useMemo(
      () => getOrderedFacets(config, facets, searchSummary),
      [config, facets, searchSummary]
    );

    const handleUpdateSearch = useCallback(
      (modifications: SearchModification[]): void => {
        log(TRACKING_EVENTS.MODIFY_FILTER, {
          modifications
        });
        history.push(getUpdatedSearch(modifications));
      },
      [getUpdatedSearch, history, log]
    );

    useEffect(() => {
      setActiveChipIndex(0);
    }, [appliedCount]);

    return (
      <HorizontalFilters
        role="toolbar"
        aria-orientation={ariaOrientation}
        onKeyDown={onKeyDown}
        tabIndex={-1}
        onBlur={() => setActiveChipIndex(0)}
      >
        {orderedFacets.map((facet, index) => (
          <FilterChip
            key={facet.field}
            activeChip={activeChipIndex}
            facet={facet}
            updateSearch={handleUpdateSearch}
            index={index}
            log={log}
            onFocus={() => setActiveChipIndex(index)}
            searchSummary={searchSummary}
            config={config}
          />
        ))}
      </HorizontalFilters>
    );
  }
  // TODO: remove this typecast once we have fully migrated to react 17
) as any as (props: BaseFilterChipsProps) => ReactElement;
