import { defineMessages } from '@kyruus/intl';

export default defineMessages({
  topfilter: {
    id: 'facet.labels.topfilter',
    description: 'Label for the most popular filter',
    defaultMessage: 'Popular choices'
  },
  allfilter: {
    id: 'facet.labels.allfilter',
    description: 'Label for all other filter options',
    defaultMessage: 'All choices'
  },
  defaultplaceholder: {
    id: 'facet.typeahead.placeholder.default',
    description: 'Default placeholder text for a typeahead facet',
    defaultMessage: 'Type here...'
  },
  arialabel: {
    id: 'aria.label.facet.typeahead',
    description: 'Accessibility label for a typeahead facet',
    defaultMessage: 'Type here for filter options'
  },
  within: {
    id: 'facet.location.within',
    description: "Title text for a relative distance, e.g. 'Within 5 miles of'",
    defaultMessage: 'Within'
  },
  within_aria_label: {
    id: 'facet.location.within.aria_label',
    description: 'Aria label for the within this distance select input',
    defaultMessage: 'Select within distance'
  },
  anymiles: {
    id: 'facet.location.anymiles',
    description: "Text to display when distance in miles is set to 'Any'",
    defaultMessage: 'Any miles'
  },
  numericalmiles: {
    id: 'facet.location.numericalmiles',
    description:
      'Text to display when distance in miles is set to a numerical value',
    defaultMessage: `{distance, plural,
      one {# mile}
      other {# miles}
    }`
  },
  apply: {
    id: 'facet.location.apply',
    description: 'Button text to apply changes to the distance facet',
    defaultMessage: 'Apply'
  },
  placeholder: {
    id: 'facet.location.placeholder',
    description: 'Placeholder text for the location facet',
    defaultMessage: 'City, State or ZIP'
  },
  closeFilterPopoverAriaLabel: {
    id: 'filter_bar.close_filter_popover.aria_label',
    description: 'Aria label for close filter popover',
    defaultMessage: 'Close filter menu'
  },
  showmore: {
    id: 'facet.showmore',
    description: 'Title for button to show more results',
    defaultMessage: 'Show More'
  },
  showless: {
    id: 'facet.showless',
    description: 'Title for button to show fewer results',
    defaultMessage: 'Show Less'
  }
});
