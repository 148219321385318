import React from 'react';
import { defineMessages } from '@kyruus/intl';

import { getConfigProperty } from 'Common/config';

import { IntlHelmet } from '../utils/intl-components';

const messages = defineMessages({
  pagetitlebasic: {
    id: 'providerlist.pagetitle.basic',
    description: 'The title of the page containing search results',
    defaultMessage: 'ProviderMatch'
  },
  pagetitlewithquery: {
    id: 'providerlist.pagetitle.withquery',
    description: 'The title of the page containing search results',
    defaultMessage: '{searchTerm} - ProviderMatch'
  },
  pagetitlewithqueryandlocation: {
    id: 'providerlist.pagetitle.withqueryandlocation',
    description: 'The title of the page containing search results',
    defaultMessage: '{searchTerm} in {location} - ProviderMatch'
  },
  pagedescriptionbasic: {
    id: 'providerlist.pagedescription.basic',
    description: 'The description of the page containing search results',
    defaultMessage: 'ProviderMatch'
  },
  pagedescriptionwithquery: {
    id: 'providerlist.pagedescription.withquery',
    description: 'The description of the page containing search results',
    defaultMessage: '{searchTerm} - ProviderMatch'
  }
});

function SearchMetas({ config, match, searchSummary }) {
  const primaryDomain = getConfigProperty(config, 'primary_domain');
  const host = getConfigProperty(config, 'host');

  const buildCanonicalSeachUrl = (host, match, searchSummary) => {
    const { page = 1 } = searchSummary;
    let path = '';
    if (match.path === '/specialty/:specialty/near/:location') {
      path = `/specialty/${encodeURIComponent(
        decodeURIComponent(match.params.specialty)
      )}/near/${encodeURIComponent(decodeURIComponent(match.params.location))}`;
    } else if (match.path === '/specialty/:specialty') {
      path = `/specialty/${encodeURIComponent(
        decodeURIComponent(match.params.specialty)
      )}`;
    } else if (match.path === '/search') {
      path = '/search';
    }
    return `${host}${path}?page=${page}`;
  };

  const canonicalUrl = buildCanonicalSeachUrl(
    primaryDomain || host,
    match,
    searchSummary
  );

  const searchTerm = searchSummary.search_description || '';
  const location =
    searchSummary.display_location || searchSummary.location || '';

  let titleMessageDescriptor, descriptionMessageDescriptor;
  if (searchTerm && location) {
    titleMessageDescriptor = messages.pagetitlewithqueryandlocation;
    descriptionMessageDescriptor = messages.pagedescriptionwithquery;
  } else if (searchTerm) {
    titleMessageDescriptor = messages.pagetitlewithquery;
    descriptionMessageDescriptor = messages.pagedescriptionwithquery;
  } else {
    titleMessageDescriptor = messages.pagetitlebasic;
    descriptionMessageDescriptor = messages.pagedescriptionbasic;
  }

  const links = [];

  if (!config?.seo?.disable_canonical_tags_listings_pages) {
    links.push({
      href: canonicalUrl,
      rel: 'canonical'
    });
  }

  return (
    <IntlHelmet
      titleMessageDescriptor={titleMessageDescriptor}
      titleMessageDescriptorValues={{ searchTerm, location }}
      metaMessageDescriptors={[
        {
          name: 'description',
          contentMessageDescriptor: descriptionMessageDescriptor,
          contentMessageDescriptorValues: { searchTerm }
        }
      ]}
      link={links}
    />
  );
}

export default SearchMetas;
