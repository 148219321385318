import React, { KeyboardEvent, MouseEvent } from 'react';
import { PMCConfig } from '@kyruus/types';
import {
  SearchSummary,
  SearchModification,
  SearchFacetWithAppliedTerms,
  Log
} from 'Src/types';
import { Content } from './Content';
import { FilterChipPopover } from '../../styles';

interface FilterPopoverProps {
  config: PMCConfig;
  facet: SearchFacetWithAppliedTerms;
  updateSearch: (modifications: SearchModification[]) => void;
  log: Log;
  onClose: (
    event: KeyboardEvent | MouseEvent,
    reason: 'escapeKeyDown' | 'backdropClick' | 'closeButton'
  ) => void;
  open: boolean;
  searchSummary: SearchSummary;
  targetEl: React.RefObject<HTMLDivElement>;
}

export const FilterPopover = ({
  config,
  facet,
  updateSearch,
  log,
  onClose,
  open,
  searchSummary,
  targetEl
}: FilterPopoverProps) => {
  return (
    <FilterChipPopover
      targetEl={targetEl.current}
      data-testid={`${facet.field}-popover`}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      showArrow={false}
    >
      <Content
        searchSummary={searchSummary}
        customerConfig={config}
        facet={facet}
        updateSearch={updateSearch}
        log={log}
        handleClose={onClose}
      />
    </FilterChipPopover>
  );
};
