import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SearchContainerV9 from '../containers/search-container-v9';
import ErrorMessage from '../error/error';
import RedirectErrorMessage from '../error/redirect-error';
import SearchWidgetPage from '../shared/search-widget-page';
import { placements } from '../tracking/constants';

// returns a placement prop used in tracking event for the search widget
const getWidgetPlacementByLocation = ({ location }) => {
  return location.pathname === '/'
    ? placements.SEARCH
    : placements.SEARCH_RESULTS;
};

function HomeV9(props) {
  const isRedirectError =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.redirect_error;
  return (
    <SearchWidgetPage
      {...props}
      placement={getWidgetPlacementByLocation(props)}
    >
      <Switch>
        {isRedirectError && <Route component={RedirectErrorMessage} />}
        <Route exact={true} path="/" />
        <Route
          path="/search"
          render={(routeProps) => (
            <SearchContainerV9 {...routeProps} log={props.log} />
          )}
        />
        <Route
          path="/specialty/:specialty/near/:location"
          render={(routeProps) => (
            <SearchContainerV9 {...routeProps} log={props.log} />
          )}
        />
        <Route
          path="/specialty/:specialty"
          render={(routeProps) => (
            <SearchContainerV9 {...routeProps} log={props.log} />
          )}
        />
        <Route component={ErrorMessage} />
      </Switch>
    </SearchWidgetPage>
  );
}

export default HomeV9;
