import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import { searchCategoryDisplayDescriptor } from './utils';

const messages = defineMessages({
  didyoumean: {
    id: 'noresults.didyoumean',
    description: 'Text for a spelling suggestion',
    defaultMessage: 'Did you mean {suggestion} in {categoryName}?'
  },
  suggestionsheader: {
    id: 'noresults.suggestionsheader',
    description: 'Header for search suggestions',
    defaultMessage: 'Suggestions based on your search:'
  }
});

function SuggestionLink({ suggestion, getUpdatedSearch, log }) {
  const logSuggestionSelected = () => {
    log('user_action.search_results.spell_suggestion_clicked', {
      suggestion: suggestion.display_text,
      suggestion_type: suggestion.display_search_type
    });
  };
  return (
    <Link
      to={getUpdatedSearch(suggestion.query_modifications)}
      className="fw-6 suggestions"
      data-action="suggestion"
      onClick={logSuggestionSelected}
    >
      {suggestion.display_text}
    </Link>
  );
}

function Suggestions({ suggestions, getUpdatedSearch, log }) {
  if (_isEmpty(suggestions)) {
    return null;
  } else {
    const suggestionsList = _map(suggestions, (suggestion, index) => {
      const suggestionLink = (
        <SuggestionLink
          suggestion={suggestion}
          getUpdatedSearch={getUpdatedSearch}
          log={log}
        />
      );
      const categoryDescriptor = searchCategoryDisplayDescriptor(
        suggestion.display_search_type
      );
      const category = <FormattedMessage {...categoryDescriptor} />;
      return (
        <li className="suggestion-message" key={index}>
          <FormattedMessage
            {...messages.didyoumean}
            values={{
              suggestion: suggestionLink,
              categoryName: category
            }}
          />
        </li>
      );
    });
    return (
      <ul className="list-m pa-m bc-soft" data-testid="noResults.suggestions">
        <h3 className="mb-s">
          <FormattedMessage {...messages.suggestionsheader} />
        </h3>
        {suggestionsList}
      </ul>
    );
  }
}

export default injectIntl(Suggestions);
