import { defineMessages } from '@kyruus/intl';

export default defineMessages({
  allFiltersChipWithCount: {
    id: 'filter_bar.all_filters.with_count.label',
    description: 'Label for all filters chip on desktop with count',
    defaultMessage: 'All Filters • {filterCount}'
  },
  allFiltersChipWithoutCount: {
    id: 'filter_bar.all_filters.without_count.label',
    description: 'Label for all filters chip on desktop without count',
    defaultMessage: 'All Filters'
  },
  allFiltersChipWithCountMobile: {
    id: 'filter_bar.all_filters.with_count.mobile.label',
    description: 'Label for all filters chip on mobile with count',
    defaultMessage: 'Sort and Filter • {filterCount}'
  },
  allFiltersChipWithoutCountMobile: {
    id: 'filter_bar.all_filters.without_count.mobile.label',
    description: 'Label for  all filters chip on mobile without count',
    defaultMessage: 'Sort and Filter'
  },
  ariaScrollLeft: {
    id: 'filter_bar.scroll_left.aria_label',
    description: 'Aria label for scroll left button',
    defaultMessage: 'Scroll left'
  },
  ariaScrollRight: {
    id: 'filter_bar.scroll_right.aria_label',
    description: 'Aria label for scroll right button',
    defaultMessage: 'Scroll right'
  },
  ariaLabelAllFiltersToolbar: {
    id: 'filter_bar.all_filters_toolbar.aria_label',
    description: 'Aria label for all filters toolbar',
    defaultMessage: 'All filters toolbar'
  },
  ariaLabelSearchFilters: {
    id: 'filter_bar.search_filters.aria_label',
    description: 'Aria label for search filters',
    defaultMessage: 'Search filters'
  }
});
