import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const StyledLabel = styled('label')`
  display: flex;
  align-items: center;
  padding-left: 0;
`;

export const StyledLabelTextAndTermCountContainer = styled('span')`
  margin-left: ${fromTheme('spacing_large')};
`;
