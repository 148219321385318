import { useState, useEffect } from 'react';
import querystring from 'querystring';
import { getConvertedSearchParams } from '../../search-v9/convert-v8-search-params';

export function useMigrateV8SearchParams({ location, history }) {
  const [completed, setCompleted] = useState(false);

  // re-write urls with v8 filters to a url with v9 filters
  useEffect(() => {
    if (location.search) {
      const queryObject = querystring.parse(location.search.substring(1));
      const v9Converted = getConvertedSearchParams(queryObject);
      if (
        querystring.stringify(queryObject) !==
        querystring.stringify(v9Converted)
      ) {
        // update search URL to rewrite v8 params to v9 params
        history.replace(
          `${location.pathname}?${querystring.stringify(v9Converted)}`
        );
      }
    }
    setCompleted(true);
  }, [location.search]);

  return {
    completed
  };
}
