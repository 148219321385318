import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, FormattedMessage } from '@kyruus/intl';

import { AppointmentInfo as BaseAppointmentInfo } from '@kyruus/appointment-info';
import Banner from '@kyruus/banner';

import PleaseCallMessage from './please-call-message';
import PhoneNumberLink from './phone-number-link';
import { messages as sharedMessages } from '../../shared/messages';

const messages = defineMessages({
  globalPatientRelTitle: {
    id: 'global.patientrel.title',
    description:
      'Patient relationship text when querying availability for all providers on page.',
    defaultMessage:
      'Are you booking with a new provider or your current provider?'
  },
  globalNewPatientButtonText: {
    id: 'global.patientrel.new.buttontext',
    description:
      'Button text for "new" patientRel button when querying availability for all providers on page.',
    defaultMessage: 'A New Provider'
  },
  globalExistingPatientButtonText: {
    id: 'global.patientrel.existing.buttontext',
    description:
      'Button text for "existing" patientRel button when querying availability for all providers on page.',
    defaultMessage: 'Your Current Provider'
  },
  existingPatientButtonText: {
    id: 'patientrel.existing.buttontext',
    description:
      'Button text for "existing" patientRel button when querying availability for a single provider.',
    defaultMessage: 'Yes'
  },
  newPatientButtonText: {
    id: 'patientrel.new.buttontext',
    description:
      'Button text for "new" patientRel button when querying availability for a single provider.',
    defaultMessage: 'No'
  },
  patientRelTitle: {
    id: 'step_title.existing_patient',
    description: 'Title to render above patient rel buttons.',
    defaultMessage: 'Have you seen {display_name} before?'
  },
  appointmentPurposeTitle: {
    id: 'step_title.appointment_reason',
    description: 'Text for the appointment purpose step title',
    defaultMessage: 'What is the main reason for your visit?'
  },
  contactoffice: {
    id: 'error.contact_office',
    description: 'Tell user to contact office directly to book appt',
    defaultMessage:
      'Please contact the office directly at {phone_number_link} to book an appointment.'
  }
});

function AppointmentInfo({
  patientRel,
  purpose,
  onPatientRelSelect,
  onSelectPurpose,
  patientRelOptions,
  providerDisplayName,
  providerPhone,
  availabilityButtonsConfig,
  isPurposeLoading,
  log,
  patientRelHasNoAvailability,
  purposeHasNoAvailability,
  hidePatientRel,
  apptPurposeFilterProps,
  formName,
  intl
}) {
  const isGlobal = !providerDisplayName;
  if (isGlobal && !isPurposeLoading) {
    const optionsForCurrentPatientRel = patientRelOptions[patientRel] || [];
    patientRelHasNoAvailability = !optionsForCurrentPatientRel.length;
  }

  const shouldShowPurpose =
    isPurposeLoading || (patientRel && !patientRelHasNoAvailability);

  const patientRelTitle = isGlobal ? (
    <FormattedMessage {...messages.globalPatientRelTitle} />
  ) : (
    <FormattedMessage
      {...messages.patientRelTitle}
      values={{ display_name: providerDisplayName }}
    />
  );

  const apptPurposeTitle = (
    <FormattedMessage {...messages.appointmentPurposeTitle} />
  );
  // customers with read-only Availability Tiles will not have a DBW config
  // instead use application string templates
  const localButtonConfig = availabilityButtonsConfig ?? [
    {
      patient_type: 'new',
      button_text: intl.formatMessage(messages.newPatientButtonText)
    },
    {
      patient_type: 'existing',
      button_text: intl.formatMessage(messages.existingPatientButtonText)
    }
  ];
  const buttonConfig = isGlobal
    ? [
        {
          patient_type: 'new',
          button_text: intl.formatMessage(messages.globalNewPatientButtonText)
        },
        {
          patient_type: 'existing',
          button_text: intl.formatMessage(
            messages.globalExistingPatientButtonText
          )
        }
      ]
    : localButtonConfig;

  const noApptPurposesMessage = isGlobal
    ? sharedMessages.noapptpurposesGlobal
    : sharedMessages.noapptpurposes;

  const NoBookingBanner = () => (
    /*
      applying lineHeight: 1.42857 here so the NoBookingBanner's spacing in PMC
      matches the NoBookingBanner in PMCDB
      TODO: https://kyruus.jira.com/browse/KENG-18878
    */
    <Banner
      icon="!"
      type="warning"
      style={{ lineHeight: 1.42857 }}
      data-testid="NoBookingBanner"
    >
      <FormattedMessage
        {...noApptPurposesMessage}
        values={{
          provider_name: providerDisplayName,
          patient_status:
            patientRel === 'new' ? (
              <FormattedMessage {...sharedMessages.newPatientType} />
            ) : (
              <FormattedMessage {...sharedMessages.establishedPatientType} />
            )
        }}
      />

      <br />

      {providerPhone && (
        <FormattedMessage
          {...messages.contactoffice}
          values={{
            phone_number_link: (
              <PhoneNumberLink phone={providerPhone} log={log} />
            )
          }}
        />
      )}
    </Banner>
  );

  return (
    <React.Fragment>
      <BaseAppointmentInfo
        isApptPurposeLoading={isPurposeLoading}
        patientRelTitle={patientRelTitle}
        apptPurposeTitle={apptPurposeTitle}
        buttonConfig={buttonConfig}
        purposeOptionsByRel={patientRelOptions}
        patientRel={patientRel}
        purpose={purpose}
        onPatientRelSelect={onPatientRelSelect}
        onSelectPurpose={onSelectPurpose}
        hidePatientRel={hidePatientRel}
        showPurpose={shouldShowPurpose}
        showNoBooking={patientRelHasNoAvailability}
        NoBookingBanner={<NoBookingBanner />}
        PleaseCallMessage={
          !isGlobal &&
          !purposeHasNoAvailability && (
            <PleaseCallMessage providerPhone={providerPhone} />
          )
        }
        apptPurposeFilterProps={apptPurposeFilterProps}
        formName={formName}
      />
      {purposeHasNoAvailability ? <NoBookingBanner /> : null}
    </React.Fragment>
  );
}

AppointmentInfo.defaultProps = {
  patientRel: '',
  purpose: null,
  onPatientRelSelect: () => {},
  onSelectPurpose: () => {},
  patientRelOptions: {},
  hidePatientRel: false
};

AppointmentInfo.propTypes = {
  patientRel: PropTypes.string,
  purpose: PropTypes.object,
  onPatientRelSelect: PropTypes.func,
  onSelectPurpose: PropTypes.func,
  patientRelOptions: PropTypes.object,
  hidePatientRel: PropTypes.bool,
  apptPurposeFilterProps: PropTypes.object
};

export default injectIntl(AppointmentInfo);
