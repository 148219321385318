import React from 'react';
import Theme from '@kyruus/ui-theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LoadingOverlay from '../shared/loading';

import { FacetPanel } from './facet-panel';
import SideSheet from './SideSheet';

/**
 * Renders the side drawer filter panel component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Flag indicating whether the side drawer is open.
 * @param {Function} props.closeDrawer - Function to close the side drawer.
 * @param {boolean} props.showMobileFacets - Flag indicating whether to show mobile facets.
 * @param {string} props.searchSummary - The search summary.
 * @param {Array} props.sortOptions - The sort options.
 * @param {number} props.totalProviders - The total number of providers.
 * @param {Object} props.log - The log object.
 * @param {Function} props.getUpdatedSearch - Function to get updated search.
 * @param {Function} props.toggleMobileFacets - Function to toggle mobile facets.
 * @param {Object} props.facets - The facets object.
 * @param {boolean} props.distanceSort - Flag indicating whether to sort by distance.
 * @param {Object} props.config - The configuration object.
 * @param {Object} props.history - The history object.
 * @param {Object} props.tokens - The tokens object.
 * @param {string} props.mapSearchUrl - The map search URL.
 * @returns {JSX.Element} The rendered SideDrawerFilterPanel component.
 */
function SideDrawerFilterPanel({
  isOpen,
  closeDrawer,
  showMobileFacets,
  searchSummary,
  sortOptions,
  totalProviders,
  log,
  getUpdatedSearch,
  toggleMobileFacets,
  facets,
  distanceSort,
  config,
  history,
  tokens,
  mapSearchUrl,
  loading,
  showFiltersInDrawer // showFiltersInDrawer is a temporary prop to show filter in drawer
}) {
  const isScreenMediumOrBelow = useMediaQuery(
    `(max-width:${Theme.screen_medium})`
  );

  return (
    <SideSheet
      dataTestId="SearchFilterDrawer"
      anchor="right"
      width={isScreenMediumOrBelow ? '100vw' : '24vw'}
      minWidth="240px"
      isOpen={isOpen}
      closeDrawer={closeDrawer}
      searchSummary={searchSummary}
      getUpdatedSearch={getUpdatedSearch}
      log={log}
    >
      <LoadingOverlay loading={loading} />
      <FacetPanel
        showMobileFacets={showMobileFacets}
        searchSummary={searchSummary}
        sortOptions={sortOptions}
        totalProviders={totalProviders}
        log={log}
        getUpdatedSearch={getUpdatedSearch}
        toggleMobileFacets={toggleMobileFacets}
        facets={facets}
        distanceSort={distanceSort}
        showLocationFacet={true}
        config={config}
        history={history}
        tokens={tokens}
        mapSearchUrl={mapSearchUrl}
        showFiltersInDrawer={showFiltersInDrawer} // showFiltersInDrawer is a temporary prop to show filter in drawer
        isScreenMediumOrBelow={isScreenMediumOrBelow}
      />
    </SideSheet>
  );
}

export default SideDrawerFilterPanel;
