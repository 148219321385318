import { defineMessages } from '@kyruus/intl';

export default defineMessages({
  removeFilterChipLabel: {
    id: 'facet_chips.remove_filter',
    description: 'Label for removing a search facet chip',
    defaultMessage: 'Remove filter'
  },
  appliedLocationFilterChipLabel: {
    id: 'facet_chips.location',
    description: 'Summary of search location information',
    defaultMessage:
      'Within {distance, select, Any {any miles} any {any miles} other {{distance, plural, one {# mile} other {# miles}}}} of {location}'
  },
  filterChipAriaLabel: {
    id: 'facet_chip.applied-filter-aria-label',
    description: 'Filter chip aria label',
    defaultMessage: '{chipLabel}. To delete this filter, press delete.'
  }
});
