import { KeyboardEvent } from 'react';

interface UseFocusManagerProps {
  /** The role used to identify the element containing list items.  */
  itemParentRole: string;
  /** The role used to identify elements within the list. */
  itemElementRole: string;
  /** Indicates whether the list is horizontally or vertically oriented. Used for keyboard navigation. */
  orientation?: 'horizontal' | 'vertical';
}

interface UseFocusManagerReturnType<ListElementType extends Element> {
  /** ARIA attribute for the list element used to indicate whether the list is rendered horizontally or vertically. */
  'aria-orientation': 'horizontal' | 'vertical';
  /** KeyDown handler for the list element. */
  onKeyDown: (event: KeyboardEvent<ListElementType>) => void;
}

/**
 * A hook for managing focus of elements within a list.
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/Keyboard-navigable_JavaScript_widgets
 * @param props props for configuring the hook
 * @param props.itemElementRole the role of the item elements
 * @param props.orientation indicates whether the list is horizontally or vertically oriented
 * @returns properties to be applied to the list element
 */
export const useFocusManager = <
  ItemElementType extends Element,
  ListElementType extends Element
>({
  itemParentRole,
  itemElementRole,
  orientation = 'horizontal'
}: UseFocusManagerProps): UseFocusManagerReturnType<ListElementType> => {
  const handleKeyDown = (event: KeyboardEvent<ListElementType>) => {
    const { target } = event;
    const targetElement = target as ItemElementType;
    const role = targetElement?.getAttribute('role');

    if (role !== itemElementRole) {
      return;
    }

    const previousKey = orientation === 'horizontal' ? 'ArrowLeft' : 'ArrowUp';
    const nextKey = orientation === 'horizontal' ? 'ArrowRight' : 'ArrowDown';

    const container = targetElement.closest(`div[role=${itemParentRole}]`);
    const allItems = container?.querySelectorAll(
      `div[role=${itemElementRole}]`
    );
    const currentIndex = Number(targetElement.getAttribute('data-index'));

    if (allItems == null || allItems.length === 0) return;

    const firstItem = allItems[0];
    const lastItem = allItems[allItems.length - 1];
    const nextItem = allItems[currentIndex + 1];
    const previousItem = allItems[currentIndex - 1];

    let newFocusItem = null;
    switch (event.key) {
      case previousKey:
        newFocusItem = previousItem;
        if (newFocusItem == null) {
          newFocusItem = lastItem;
        }
        break;
      case nextKey:
        newFocusItem = nextItem;
        if (newFocusItem == null) {
          newFocusItem = firstItem;
        }
        break;
      case 'Home':
        newFocusItem = firstItem;
        break;
      case 'End':
        newFocusItem = lastItem;
        break;
      default:
        break;
    }
    if (newFocusItem != null) {
      (newFocusItem as HTMLElement)?.focus();
      event.preventDefault();
    }
  };
  return {
    'aria-orientation': orientation,
    onKeyDown: handleKeyDown
  };
};
