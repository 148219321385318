import { ButtonLink, Button } from '@kyruus/button';
import { styled as biomeStyled } from '@biome-design-system/styles';
import { fromTheme } from '@kyruus/ui-theme';
import Chip from '@kyruus/chip';
import Popover from '@kyruus/popover';
import Cancel from '@kyruus/react-ikons/Cancel';
import Paper from '@kyruus/paper';

export const HorizontalFilterBar = biomeStyled('div')`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${fromTheme('spacing_medium')};
    gap: ${fromTheme('spacing_small')};
  }
`;

export const MapButton = biomeStyled(ButtonLink)`
  align-self: baseline;
  white-space: nowrap;

  &:visited {
    color: ${fromTheme('color_text')}
  }

  &:hover {
    text-decoration: none;
    color: ${fromTheme('color_text_contrast')};
  }
`;

export const AllFiltersButton = biomeStyled(Button)`
  white-space: nowrap;
  align-self: baseline;
  border-radius: 16px; // match material-ui chip
  
  svg {
    padding-right: ${fromTheme('spacing_small')};
    font-size: ${fromTheme('font_size')};
  }
  span, svg {
    vertical-align: middle;
  }
`;

export const FilterChipWrapper = biomeStyled('div')`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const ScrollButtonContainer = biomeStyled('div')<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  // allow for button active state
  padding: 2px;

  visibility: hidden;
  opacity: 0;
  transition: opacity 150ms ease-in, visibility 0ms ease-in 150ms;
  ${(props) =>
    props.visible &&
    `visibility: visible;
    opacity: 1;
    transition-delay: 0ms;
    `}

  svg {
    font-size: 22px;
  }

  button {
    height: 100%;
    padding: 0 ${fromTheme('spacing_small')};

    &:focus,
    &:active {
      outline-offset: -2px;
    }
  }
`;

export const ScrollLeft = biomeStyled(ScrollButtonContainer)`
  padding-right: ${fromTheme('spacing_medium')};
  background: linear-gradient(
    to right,
    ${fromTheme('color_background')} 80%,
    transparent
  );
  left: 0;
`;

export const ScrollRight = biomeStyled(ScrollButtonContainer)`
  padding-left: ${fromTheme('spacing_medium')};
  background: linear-gradient(
    to left,
    ${fromTheme('color_background')} 80%,
    transparent
  );
  right: 0;
`;

export const FilterChip = biomeStyled(Chip)`
  && {
    font-family: ${fromTheme('font_face')};
    font-size: ${fromTheme('font_size')};
    font-weight: ${fromTheme('font_weight')};
    line-height: ${fromTheme('font_line_height')};
    background-color: ${fromTheme('color_providermatch')}10;
    border: ${fromTheme('border_width')} ${fromTheme(
  'border_style'
)} ${fromTheme('color_providermatch')};
    color: ${fromTheme('color_text')};
    cursor: pointer;

    &:focus, &:hover, &:active {
      background-color: ${fromTheme('color_providermatch')}20;
    }
  }
`;

export const UnappliedFilterChip = biomeStyled(FilterChip)`
  && {
    font-family: ${fromTheme('font_face')};
    font-size: ${fromTheme('font_size')};
    font-weight: ${fromTheme('font_weight')};
    background-color: white;
    border: ${fromTheme('border_width')} ${fromTheme(
  'border_style'
)} ${fromTheme('color_text')};
    cursor: pointer;
  }

  &:focus, &:hover {
    background-color: ${fromTheme('color_providermatch')}20;
  }
`;

export const UnappliedFilterChipLabel = biomeStyled('span')`
  display: flex;
  align-items: center;

  >svg {
    margin-top: -2px;
  }
`;

export const HorizontalFilters = biomeStyled('div')`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: ${fromTheme('spacing_small')};
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;

  // safari rules
  padding: ${fromTheme('border_width')};
  ::-webkit-scrollbar {
    display: none;
}
`;

export const RemoveFilterChipIcon = biomeStyled(Cancel)`
  && {
    background: none;
    border: none;
    padding: 0;
    margin-top: -4px;
    color: ${fromTheme('color_text')};

    &:hover,
    &:focus,
    &:focus-visible,
    &:active,
    &:active:not(:disabled) {
      background: none;
      outline: none;
      color: ${fromTheme('color_text')};
    }
  }
`;

export const FilterChipPopover = biomeStyled(Popover)`
  && .MuiPopover-paper {
    overflow: visible;
  }
`;

export const FilterTitle = biomeStyled('legend')`
  font-size: ${fromTheme('font_size_heading_5')};
  font-weight: ${fromTheme('font_weight_bold')};
  line-height: ${fromTheme('font_line_height')};
  margin: auto 0;
  padding-left: ${fromTheme('spacing_small')};
`;

export const Filter = biomeStyled(Paper)`
  display: flex;
  width: 360px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: var(--gray-gray-700, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const FilterHeader = biomeStyled('div')`
  display: flex;
  padding: ${fromTheme('spacing_medium')};
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
