// map of [V9 field] to [v8 options that convert to that v9 field]
// exporting as a commonjs module as migration scripts are unable to import this dependency otherwise (likely due to build configuration)
module.exports.V9_FACET_FIELD_MIGRATIONS = {
  // this dict mostly comes from search-service code
  // https://bitbucket.org/kyruus/search_service/src/7a8435c1885b4d2150a9d78d9735de4c34d02b6f/searchservice/v9/models/constants.py?at=v8_development#lines-112
  customer: 'customer.name',
  attributes: 'attributes',
  'availability.meta.availability_density_best': 'availability_density_best',
  expertise: 'kcl.expertise_ids',
  insurance_cluster: 'insurance_clusters',
  'locations.city': ['locations.city', 'address.city'],
  'locations.id': 'locations.id',
  'locations.name': [
    'locations.name',
    'locations.name.keyword',
    'address.facility_name_render',
    'address.facility_name_search'
  ],
  'locations.primary_marketable_location_id':
    'locations.primary_marketable_location_id',
  'locations.state': 'locations.state',
  'locations.zip': 'locations.zip',
  name: 'name.keyword',
  'provider.accepting_new_patients': 'accepting_new_patients',
  'provider.additional_services': 'additional_services',
  'provider.additional_visit_types': 'additional_visit_types',
  'provider.age_groups_seen': ['age_groups_seen', 'age_groups_seen.name'],
  'provider.aha_id': 'aha_id',
  'provider.always_available_provider': 'always_available_provider',
  'provider.appointment_ehr_purposes': 'appointment_ehr_purposes',
  'provider.appointment_ehr_purposes.name': 'appointment_ehr_purposes.name',
  'provider.appointment_ehr_purposes.patient_relationship':
    'appointment_ehr_purposes.patient_relationship',
  'provider.appointment_ehr_purposes.visit_method':
    'appointment_ehr_purposes.ehr_data.visit_method',
  'provider.care_team': 'care_team',
  'provider.case_types_seen': 'case_types_seen',
  'provider.clinical_keywords.promoted.id': 'kcl.concept_ids.promoted',
  'provider.clinical_keywords.searchable.id': 'kcl.concept_ids.searchable',
  'provider.clinical_programs': 'clinical_programs',
  'provider.credentialed_specialty': 'credentialed_specialty',
  'provider.degrees.name': 'degrees.name',
  'provider.department_name': 'department_name',
  'provider.direct_book_capable': 'direct_book_capable',
  'provider.direct_book_pmac': 'direct_book_pmac',
  'provider.direct_book_pmc': 'direct_book_pmc',
  'provider.ehr_platform': 'ehr_platform',
  'provider.employment_status': 'employment_status',
  'provider.entity_type': 'entity_type',
  'provider.external_id': 'external_id',
  'provider.external_systems': 'external_systems',
  'provider.gender': 'gender',
  'provider.has_video_url': 'has_video_url',
  'provider.hospital_affiliations': [
    'hospital_affiliations',
    'hospital_affiliations.name'
  ],
  'provider.id': 'id',
  'provider.institutes': 'institutes',
  'provider.insurance_accepted': ['insurance_plans', 'insurance_accepted'],
  'provider.is_primary_care': 'is_primary_care',
  'provider.is_specialty_care': 'is_specialty_care',
  'provider.languages': ['languages', 'languages.language'],
  'provider.lgbtq_inclusive': [
    'lgbtqia_friendly',
    'lgbt_friendly',
    'lgbtq_inclusive',
    'lgbtq_friendly'
  ],
  'provider.location_type': 'location_type',
  'provider.manually_created': 'manually_created',
  'provider.marketing_campaign_name': 'marketing_campaign_name',
  'provider.md_anderson': 'md_anderson',
  'provider.metadata.provider_last_modified': 'metadata.provider_last_modified',
  'provider.metadata.provider_last_updated': 'metadata.provider_last_updated',
  'provider.name': 'name',
  'provider.name.preferred': 'preferred_name',
  'provider.near_term_availability.network': 'near_term_availability.network',
  'provider.network_affiliations.name': [
    'network_affiliations.name.keyword',
    'hospital',
    'generic.hospital',
    'network_affiliations.name',
    'networks.network',
    'network_affiliations'
  ],
  'provider.network_affiliations.type': 'network_affiliations.type',
  'provider.networks': [
    'networks',
    'near_term_availability.network',
    'networks.network'
  ],
  'provider.new_pt_online_booking': 'new_pt_online_booking',
  'provider.night_weekend_availability': 'night_weekend_availability',
  'provider.npi': 'npi',
  'provider.online_book_check': 'online_book_check',
  'provider.open_scheduling': 'open_scheduling',
  'provider.pmc_db_only_provider': 'pmc_db_only_provider',
  'provider.pmc_db_only_provider_flag': 'pmc_db_only_provider_flag',
  'provider.po_group': 'po_group',
  'provider.practice_groups': ['departments'],
  'provider.practice_groups.name': [
    'practice_groups.name.keyword',
    'departments_full.name',
    'practice_groups.name'
  ],
  'provider.promotion_campaign': 'promotion_campaign',
  'provider.provider_is_employed': 'provider_is_employed',
  'provider.provider_organization_name': 'provider_organization_name',
  'provider.provider_organizations': 'provider_organizations',
  'provider.provider_organizations.provider_organization_id':
    'provider_organizations.provider_organization_id',
  'provider.provider_type': 'provider_type',
  'provider.region': 'region',
  'provider.scope_of_practice.name': 'scope_of_practice.concepts.name',
  'provider.service_line': 'service_line',
  'provider.show_in_pmc': 'show_in_pmc',
  'provider.sort_field': 'sort_field',
  'provider.sort_preferences.networks': 'sort_preferences.networks',
  'provider.specialties.name': 'specialties.specialty.untouched',
  'provider.specialties.subspecialties.name':
    'specialties.subspecialty.untouched',
  'provider.specialty_services': 'specialty_services',
  'provider.start_date': 'start_date',
  'provider.surgical_status': 'generic.surgical_status',
  'provider.sylvester_disease_group': 'sylvester_disease_group',
  'provider.syndicate_to_google': 'syndicate_to_google',
  'provider.telehealth': 'telehealth',
  'provider.telehealth_badge': 'telehealth_badge',
  'provider.tytocare_virtual_visit': 'tytocare_virtual_visit',
  'provider.urgent_care_location': 'urgent_care_location',
  'provider.video_visit': 'video_visit',
  'provider.virtual_care': 'virtual_care',
  'provider.virtual_care_url': 'virtual_care_url',
  'provider.walkins_welcome': 'walkins_welcome',
  'provider.xp_emory_affiliation': 'xp_emory_affiliation',
  'provider.xp_provider_group': 'xp_provider_group',
  type: 'type',
  version: 'version'
};

/**
 * Map of v8 facet values to v9 facet values
 *
 * Structure
 * {
 *  v8_field_name: {
 *    field: 'v9_field_name',
 *    filter: [ 'v9 filter' ],
 *    remove: 'values to remove from field value'
 *  }
 * }
 */
module.exports.V8_TO_V9_FIELD_MIGRATIONS = {
  clinical_experience: { field: 'clinical_keywords' },
  clinical_experience_pcp: {
    field: 'clinical_keywords',
    filter: ['provider.is_primary_care:true']
  },
  name: { field: 'provider_name' },
  practice_location: { field: 'location_name' },
  primary_care_condition: {
    field: 'clinical_keywords',
    filter: ['provider.is_primary_care:true']
  },
  primary_care_condition_ranked: {
    field: 'clinical_keywords',
    filter: ['provider.is_primary_care:true']
  },
  specialty_strict: { field: 'specialties', remove: '.*' },
  specialty_synonym: { field: 'specialties', remove: '.*' },
  specialty_all: { field: 'specialties', remove: '.*' }
};
