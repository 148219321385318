import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';
import Button, { ButtonLink } from '@kyruus/button';

const styledIconOptions = {
  // Filter width attr from being forwarded to the section as prop to prevent validation errors
  shouldForwardProp: (propName) => propName !== 'width'
};

export const Content = styled('section', styledIconOptions)`
  width: ${(props) => props.width || '50vw'};
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : '')}
  padding: 0 ${fromTheme('spacing_large')};
`;

export const FixedHeader = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${fromTheme('spacing_large')};
  border-bottom: 1px solid ${fromTheme('color_border')};
  height: 55px;
  width: 100%;
  padding: 0 ${fromTheme('spacing_medium')};
  overflow: hidden;
  background: ${fromTheme('color_background')};

  > span {
    font-size: ${fromTheme('font_size_heading_5')};
    font-weight: ${fromTheme('font_weight_bolder')};
  }

  button {
    border: none;
    background: none;
  }
`;

export const FixedFooter = styled(FixedHeader)`
  bottom: 0;
`;

export const Footer = styled(Header)`
  display: grid;
  grid-template-areas: 'clear-all-filters view-results';
  border-top: 1px solid ${fromTheme('color_border')};
  border-bottom: 0;
  margin-bottom: 0;
`;

export const ClearAllFiltersButton = styled(ButtonLink)`
  grid-area: clear-all-filters;

  &:visited {
    color: ${fromTheme('color_text')};
  }

  &:hover {
    text-decoration: none;
    color: ${fromTheme('color_text_contrast')};
  }
`;

export const ViewResultsButton = styled(Button)`
  grid-area: view-results;
`;

export const DrawerRelativeWrapper = styled('div')`
  position: relative;
`;
