import { connect } from 'react-redux';

import { settingsSelector } from '../behaviors/settings';
import { configSelector } from '../behaviors/configuration';
import { searchWidgetConfigSelector } from '../behaviors/search-widget';
import { getCustomerCode } from '../behaviors/customer-code';
import HomeV9 from '../home-v9';

const mapStateToProps = (state) => {
  let settings = settingsSelector(state);
  let config = configSelector(state);
  let searchWidgetConfig = searchWidgetConfigSelector(state);
  let customerCode = getCustomerCode(state);

  return {
    settings,
    config,
    searchWidgetConfig,
    customerCode
  };
};

const HomeContainerV9 = connect(mapStateToProps)(HomeV9);

export default HomeContainerV9;
