import React, { ReactElement } from 'react';
import {
  injectIntl,
  WrappedComponentProps,
  FormattedMessage,
  type MessageDescriptor
} from '@kyruus/intl';
import _kebabCase from 'lodash/kebabCase';

import { Term } from 'Src/types';
import { ShowMoreResultsButton } from '../../../styles';
import messages from './messages';

interface BaseShowMoreButtonProps {
  allTerms: Term[];
  facetName: string;
  hideTerms: boolean;
  labelName: Pick<MessageDescriptor, 'id' | 'description' | 'defaultMessage'>;
  maxVisible: number;
  onClick: () => void;
  terms: Term[];
}

type ShowMoreButtonProps = WrappedComponentProps & BaseShowMoreButtonProps;

export const ShowMoreButton = injectIntl(
  ({
    allTerms,
    facetName,
    hideTerms,
    intl,
    labelName,
    maxVisible,
    onClick,
    terms
  }: ShowMoreButtonProps) => {
    if (allTerms.length <= maxVisible || terms.length === 0) return null;

    let showMoreLessText;
    if (hideTerms) {
      showMoreLessText = <FormattedMessage {...messages.showmore} />;
    } else {
      showMoreLessText = <FormattedMessage {...messages.showless} />;
    }

    return (
      <ShowMoreResultsButton
        id={_kebabCase(facetName) + '-facet-show-hide'}
        data-testid={_kebabCase(facetName) + '-facet-show-hide'}
        onClick={onClick}
        aria-label={`${intl.formatMessage(
          hideTerms ? messages.showmore : messages.showless
        )} ${intl.formatMessage(labelName)}`}
        mode="flat"
      >
        {showMoreLessText}
      </ShowMoreResultsButton>
    );
  }
) as any as (props: BaseShowMoreButtonProps) => ReactElement;

ShowMoreResultsButton.displayName = 'ShowMoreButton';
