import React, { useEffect, useState, ReactElement } from 'react';
import {
  injectIntl,
  WrappedComponentProps,
  FormattedMessage
} from '@kyruus/intl';
import { PMCConfig } from '@kyruus/types';
import { isModuleEnabled, MODULES } from '../../../../../../common/config';
import {
  SearchModification,
  SearchFacetWithAppliedTerms,
  SearchSummary,
  Log
} from 'Src/types';
import useSuggestions from '../../../../hooks/useSuggestions';
import { IntlOption as ExistingIntlOption } from '../../../../utils/intl-components';
import LocationInput from '../../../facet-panel/facet-list/location-facet/location-input';
import messages from './messages';
import { FilterWrapper, LocationFilterWrapper } from '../../../styles';
import { LOCATION_FACET_DEFAULT_DISTANCE } from '../../../../utils/constants';

export const DISTANCE_ANY = 'Any';

const IntlOption = ExistingIntlOption;

interface BaseLocationContentProps {
  locationFacet: SearchFacetWithAppliedTerms;
  customerConfig: PMCConfig;
  searchSummary: SearchSummary;
  isSearchableLocation: boolean;
  updateSearch: (modifications: SearchModification[]) => void;
  log: Log;
}

type LocationContentProps = WrappedComponentProps & BaseLocationContentProps;

interface SearchParams {
  searchLocation: string | number;
  searchDisplayLocation: string | number;
  distance: string | number;
}

export const LocationContent = injectIntl(
  ({
    locationFacet,
    customerConfig,
    searchSummary,
    isSearchableLocation,
    updateSearch,
    intl,
    log
  }: LocationContentProps) => {
    const defaultDistance = searchSummary.location
      ? DISTANCE_ANY
      : locationFacet.default_distance;

    const [searchParams, setSearchParams] = useState<SearchParams>({
      searchLocation: searchSummary.location || '',
      searchDisplayLocation:
        searchSummary.display_location || searchSummary.location || '',
      distance: searchSummary.distance ?? defaultDistance
    });

    // term used to fetch suggestions for
    const [inputSuggestionsTerm, setInputSuggestionsTerm] = useState<
      string | undefined
    >(undefined);

    // auto-suggest suggestions
    const { suggestions } = useSuggestions({ term: inputSuggestionsTerm });

    useEffect(() => {
      setSearchParams({
        searchLocation: searchSummary.location || '',
        searchDisplayLocation:
          searchSummary.display_location || searchSummary.location || '',
        distance: searchSummary.distance ?? defaultDistance
      });
    }, [
      searchSummary.location,
      searchSummary.display_location,
      searchSummary.distance,
      /** _original_input_distance is needed here to trigger the useEffect
       * when the searchSummary.distance remains constant due to Search V9's auto distance expansion feature */
      searchSummary._original_input_distance,
      defaultDistance
    ]);

    /**
     * Modifies the route history with the current filters and applies them to update the search results
     */
    async function applyFilter({
      searchLocation,
      searchDisplayLocation,
      distance
    }: SearchParams) {
      if (!searchLocation) {
        return;
      }

      const modifications: SearchModification[] = [
        {
          action: 'append',
          key: 'location',
          value: searchLocation
        },
        {
          action: 'append',
          key: 'display_location',
          value: searchDisplayLocation
        }
      ];

      /** Previously using Search V8, a distance of 0 would return results as if no distance filter was applied (appropriate for any "Any miles" search).
       * However, now in Search V9, a distance of 0 returns results only if they exist at specific location, otherwise the API will auto expand the distance
       * (response's messages.distance_expansion) in specific mile increments until it finds results to return.  */
      if (distance === DISTANCE_ANY) {
        modifications.push({ action: 'delete_key', key: 'distance' });
      } else {
        modifications.push({
          action: 'append',
          key: 'distance',
          value: distance
        });
      }
      updateSearch(modifications);
    }

    function handleUseCurrentLocation(
      location: string,
      displayLocation: string,
      distance: string | null
    ) {
      const defaultDistance =
        locationFacet.default_distance ?? LOCATION_FACET_DEFAULT_DISTANCE;
      const params = {
        searchLocation: location,
        searchDisplayLocation: displayLocation,
        distance: distance == null ? defaultDistance : distance
      };
      applyFilter(params);
    }

    const { searchLocation, searchDisplayLocation, distance } = searchParams;
    return (
      <FilterWrapper>
        <LocationFilterWrapper>
          <FormattedMessage {...messages.within} />
          <select
            id="distance"
            className="ml-xs mr-xs"
            onChange={(event) => {
              setSearchParams({
                searchLocation,
                searchDisplayLocation,
                distance: event.target.value
              });
              log(
                'user_action.search_results.distance_facet.within_distance_selected'
              );
            }}
            value={distance}
            aria-label={intl.formatMessage(messages.within_aria_label)}
          >
            {(locationFacet.distance_options || []).map((distance) => {
              return (
                <IntlOption
                  value={distance}
                  key={distance}
                  messageDescriptor={
                    distance === DISTANCE_ANY
                      ? messages.anymiles
                      : messages.numericalmiles
                  }
                  messageDescriptorValues={
                    distance === DISTANCE_ANY ? undefined : { distance }
                  }
                />
              );
            })}
          </select>
          <LocationInput
            searchableLocation={isSearchableLocation}
            displayLocation={searchDisplayLocation}
            suggestions={suggestions}
            onChange={(selectedItem: string) => {
              const params = {
                distance,
                searchLocation: selectedItem,
                searchDisplayLocation: selectedItem
              };
              setSearchParams(params);
              applyFilter(params);
              log('user_action.search_results.distance_facet.search_location');
            }}
            onInputValueChange={(inputValue: string) => {
              setSearchParams({
                distance,
                searchLocation: inputValue,
                searchDisplayLocation: inputValue
              });
              setInputSuggestionsTerm(inputValue);
            }}
            onSubmit={() => {
              applyFilter(searchParams);
              log('user_action.search_results.distance_facet.search_location');
            }}
            placeholder={messages.placeholder}
            /**
             * If CRM is enabled then the useCurrentLocation button causes a spinner of death because the application is iframed without geolocation access
             * If CRM is enabled or enable_use_current_location flag is not checked then return false otherwise true
             */
            enableUseCurrentLocation={
              !isModuleEnabled(customerConfig, MODULES.CRM_INTEGRATION) &&
              Boolean(locationFacet?.enable_use_current_location)
            }
            onUseCurrentLocation={handleUseCurrentLocation}
            distance={distance}
            log={log}
          />
        </LocationFilterWrapper>
      </FilterWrapper>
    );
  }
  // TODO: remove this typecast once we have fully migrated to react 17
) as any as (props: BaseLocationContentProps) => ReactElement;
