import React, { MouseEvent, useRef } from 'react';
import {
  injectIntl,
  WrappedComponentProps,
  FormattedMessage
} from '@kyruus/intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowLeft from '@kyruus/react-ikons/ArrowLeft';
import ArrowRight from '@kyruus/react-ikons/ArrowRight';
import Theme from '@kyruus/ui-theme';
import { TuneIcon } from '@biome-design-system/icons/TuneIcon';
import Button from '@kyruus/button';
import { PMCConfig } from '@kyruus/types';

import { FilterChips } from './FilterChips';
import { getUniqueAppliedFiltersCount, getAllFiltersLabel } from './utils';
import { messages as searchV9Messages } from '../utils';
import messages from './messages';
import { useHorizontalScroll } from './hooks/useHorizontalScroll';
import { GetUpdatedSearch, SearchFacet, SearchSummary, Log } from 'Src/types';
import {
  AllFiltersButton,
  ScrollLeft,
  ScrollRight,
  FilterChipWrapper,
  MapButton,
  HorizontalFilterBar
} from './styles';
import { TRACKING_EVENTS } from './constants';
export interface FilterBarProps extends WrappedComponentProps {
  onAllFiltersClick: (e: MouseEvent) => void;
  facets: SearchFacet[];
  getUpdatedSearch: GetUpdatedSearch;
  searchSummary: SearchSummary;
  config: PMCConfig;
  mapSearchUrl: string;
  onMapButtonClick: (e: MouseEvent) => void;
  log: Log;
  shouldShowMapLink: boolean;
}

export const FilterBar = injectIntl(
  ({
    onAllFiltersClick,
    facets,
    config,
    getUpdatedSearch,
    searchSummary,
    mapSearchUrl,
    onMapButtonClick,
    intl,
    log,
    shouldShowMapLink
  }: FilterBarProps) => {
    const filtersComponentRef = useRef<HTMLDivElement | null>(null);
    const {
      scrollLeft,
      scrollRight,
      canScrollLeft,
      canScrollRight,
      setScrollability
    } = useHorizontalScroll(filtersComponentRef, facets);
    const isScreenMediumOrBelow = useMediaQuery(
      `(max-width:${Theme.screen_medium})`
    );

    const locationChipCount = searchSummary.location ? 1 : 0;

    const countOfAppliedFilters =
      getUniqueAppliedFiltersCount(facets) + locationChipCount;

    const allFiltersLabel = getAllFiltersLabel(
      countOfAppliedFilters,
      isScreenMediumOrBelow
    );

    const handleAllFiltersClick = (e: MouseEvent) => {
      onAllFiltersClick(e);
      log(TRACKING_EVENTS.ALL_FILTERS_OPEN_DRAWER);
    };

    const handleScrollLeft = () => {
      scrollLeft();
      log(TRACKING_EVENTS.SCROLL_LEFT);
    };
    const handleScrollRight = () => {
      scrollRight();
      log(TRACKING_EVENTS.SCROLL_RIGHT);
    };

    return (
      <HorizontalFilterBar
        aria-label={intl.formatMessage(messages.ariaLabelAllFiltersToolbar)}
      >
        <AllFiltersButton
          data-testid="all-filters-chip"
          onClick={handleAllFiltersClick}
          mode="flat"
          type="button"
        >
          <TuneIcon style={{ color: 'inherit' }} />
          <FormattedMessage
            {...allFiltersLabel}
            values={{ filterCount: countOfAppliedFilters }}
          />
        </AllFiltersButton>
        {isScreenMediumOrBelow ? null : (
          <FilterChipWrapper
            ref={filtersComponentRef}
            onScrollCapture={setScrollability}
            aria-controls="provider-list"
            aria-label={intl.formatMessage(messages.ariaLabelSearchFilters)}
          >
            <ScrollLeft
              data-testid="scroll-filters-left"
              visible={canScrollLeft}
            >
              <Button
                aria-label={intl.formatMessage(messages.ariaScrollLeft)}
                onClick={handleScrollLeft}
                tabIndex={canScrollLeft ? undefined : -1}
              >
                <ArrowLeft aria-hidden={true} />
              </Button>
            </ScrollLeft>
            <FilterChips
              facets={facets}
              getUpdatedSearch={getUpdatedSearch}
              searchSummary={searchSummary}
              config={config}
              log={log}
            />
            <ScrollRight
              data-testid="scroll-filters-right"
              visible={canScrollRight}
            >
              <Button
                aria-label={intl.formatMessage(messages.ariaScrollRight)}
                onClick={handleScrollRight}
                tabIndex={canScrollRight ? undefined : -1}
              >
                <ArrowRight aria-hidden={true} />
              </Button>
            </ScrollRight>
          </FilterChipWrapper>
        )}
        {shouldShowMapLink && (
          <MapButton
            data-testid="MapSearchLink"
            type="button"
            href={mapSearchUrl}
            onClick={onMapButtonClick}
            aria-label={intl.formatMessage(
              searchV9Messages.mapSearchLinkAriaLabel
            )}
          >
            <FormattedMessage {...searchV9Messages.mapSearchLinkText} />
          </MapButton>
        )}
      </HorizontalFilterBar>
    );
  }
);
