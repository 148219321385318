import { KeyboardEvent } from 'react';

interface UseFocusManagerProps {
  /** The role used to identify elements within the list. */
  itemElementRole?: string;
  /** Indicates whether the list is horizontally or vertically oriented. Used for keyboard navigation. */
  orientation?: 'horizontal' | 'vertical';
}

interface UseFocusManagerReturnType<ListElementType extends Element> {
  /** ARIA attribute for the list element used to indicate whether the list is rendered horizontally or vertically. */
  'aria-orientation': string;
  /** KeyDown handler for the list element. */
  onKeyDown: (event: KeyboardEvent<ListElementType>) => void;
}

/**
 * A hook for managing focus of elements within a list.
 * @param props props for configuring the hook
 * @param props.itemElementRole the role of the item elements
 * @param props.orientation indicates whether the list is horizontally or vertically oriented
 * @returns properties to be applied to the list element
 */
export const useFocusManager = <
  ItemElementType extends Element,
  ListElementType extends Element
>({
  itemElementRole = 'tab',
  orientation = 'horizontal'
}: UseFocusManagerProps): UseFocusManagerReturnType<ListElementType> => {
  const handleKeyDown = (event: KeyboardEvent<ListElementType>) => {
    const { target } = event;
    const targetElement = target as ItemElementType;

    const role = targetElement?.getAttribute('role');
    if (role !== itemElementRole) {
      return;
    }

    const previousKey = orientation === 'horizontal' ? 'ArrowLeft' : 'ArrowUp';
    const nextKey = orientation === 'horizontal' ? 'ArrowRight' : 'ArrowDown';

    let newFocusTarget = null;

    switch (event.key) {
      case previousKey:
        newFocusTarget =
          targetElement.parentElement?.previousElementSibling
            ?.lastElementChild ??
          targetElement.parentElement?.parentElement?.lastElementChild
            ?.lastElementChild;
        break;
      case nextKey:
        newFocusTarget =
          targetElement.parentElement?.nextElementSibling?.lastElementChild ??
          targetElement.parentElement?.parentElement?.firstElementChild
            ?.lastElementChild;
        break;
      case 'Home':
        newFocusTarget =
          targetElement.parentElement?.parentElement?.firstElementChild
            ?.lastElementChild;
        break;
      case 'End':
        newFocusTarget =
          targetElement.parentElement?.parentElement?.lastElementChild
            ?.lastElementChild;
        break;
      default:
        break;
    }

    if (newFocusTarget != null) {
      (newFocusTarget as HTMLElement)?.focus();
      event.preventDefault();
    }
  };
  return {
    'aria-orientation': orientation,
    onKeyDown: handleKeyDown
  };
};
